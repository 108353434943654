import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import { RootState } from "..";
import { store } from "@/store";
import { DeliveryType, OrderCartCreateModel, OrderCartItemCreateModel, OrderCartViewModel, OrderStatus } from "@/api-client";
import { CartItems, Carts } from "@/network/api";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";
import { facebookAddToCartEvent } from '@/seo/facebook-pixel-helper'

export type CheckoutState = {
  cart: OrderCartViewModel | null;
  cartId: string;
};

export enum MutationTypes {
  SetCart = "SET_Cart",
  SetCartId = "SET_Cart_Id",
}

export const state: CheckoutState = {
  cart: null,
  cartId: ""
};

type Getters = {
  cart(state: CheckoutState): OrderCartViewModel | null;
  cartId(state: CheckoutState): string;
};

export const getters: GetterTree<CheckoutState, RootState> & Getters = {
  cart(state) {
    if (state.cart != null && state.cart) {
      return state.cart;
    }
    return null;
  },
  cartId(state) {
    if (state.cartId != null && state.cartId) {
      return state.cartId;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return "";
    }
    if (store["checkout"] == null) {
      return "";
    }
    const cartId = store["checkout"]["cartId"];
    if (cartId != "") {
      state.cartId = cartId;
      return cartId;
    }
    return "";
  },
};

type Mutations = {
  [MutationTypes.SetCart](state: CheckoutState, cart: any): void;
  [MutationTypes.SetCartId](state: CheckoutState, cartId: string): void;
};

export const mutations: MutationTree<CheckoutState> & Mutations = {
  [MutationTypes.SetCart](state, cart) {
    state.cart = cart;
  },
  [MutationTypes.SetCartId](state, cartId) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["checkout"]) {
      store["checkout"] = {};
    }
    store["checkout"]["cartId"] = cartId;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.cartId = cartId;
  },
};

const createCart = (context: any, params: any) => {
  const country = store.getters["location/country"];
  const cart = {
    countryId: country.id,
    deliveryTypeId: DeliveryType.Standard,
    currencyId: country.currency.id,
  } as OrderCartCreateModel;

  return new Promise<void>((resolve, reject) => {
    Carts.cartsCreateCartPost(cart)
      .then(async (res: any) => {
        if (res.data.succeeded) {
          // console.log("cart created", res);
          await context.commit(MutationTypes.SetCartId, res.data.resultData.id);
          await context.commit(MutationTypes.SetCart, res.data.resultData);
          resolve(res.data.resultData.id);
          //debugger
        } else {
          reject(res.data);
        }
        
      })
      .catch((error) => {
console.log(error)
        const errors = error.response.data.errors;
        errors.forEach((error: any) => {
          notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
        reject(error);
      });
  });
};

const fetchCart = async (context: any, id: any, accessKey: string | undefined) => {
    await Carts.cartsGetCartGet(id, accessKey)
    .then((res: any) => {
      if (res.data.succeeded) {
        // console.log("cart loaded", res);
        context.commit(MutationTypes.SetCart, res.data.resultData);
        context.commit(MutationTypes.SetCartId, res.data.resultData.id);

        if(res.data.resultData.isQuote) {
          store.dispatch("location/setDefaultSelection", {
            countryId: res.data.resultData.countryId
          }).then(() => {
            store.dispatch("location/getDefaultSelection")
          })
        }
      }
    })
    .catch(async (error) => {
debugger
      console.log(error)
      if(['Authorization', 'Validation'].includes(error.response.data.resultFailureType) && (router.currentRoute.value.name !== 'Login')) {
        const path = window.location.pathname;
        router.push({name: 'Login', query: { returnUrl: path, clear: 'true' }})
      } else if(['NotFound'].includes(error.response.data.resultFailureType)) {
        store.dispatch("checkout/refreshCart", {
          id: null,
          accessKey: accessKey
        });
      } else {
        const errors = error.response.data.errors;
        errors.forEach((error: any) => {
          notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      })
      }

      // console.log(error)
      //   const errors = error.response.data.errors;
      //   errors.forEach((error: any) => {
      //     notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      //   });
    });
}

export const CheckoutModule: Module<CheckoutState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    getCart: async (context, params) => {
      const isLoggedIn = store.getters["auth/isLoggedIn"];
      
      let cartId = getters.cartId(state);
      const cart = getters.cart(state);
      const accessKey = params?.accessKey || undefined;
      const quoteId = params?.quoteId || (cart?.isQuote && cart?.id);
      const isQuote = router.currentRoute.value.query.returnUrl?.includes('?quote=') ? true : false
      //TODO this was an async promise, alternative?
      return new Promise<void>((resolve, reject) => {
        if(quoteId) {
          fetchCart(context, quoteId, accessKey)
        } else if (!isQuote && (isLoggedIn && !quoteId && !cartId)) { 
          Carts.cartsGetCartIdGet()
            .then((res: any) => {
              if (res.data.succeeded) {
                //debugger
                // console.log("auth cart id found", res);
                context.commit(MutationTypes.SetCartId, res.data.resultData)
                cartId = res.data.resultData;
                if (cartId) {
                  //debugger
                  fetchCart(context, cartId, accessKey)
                }
                resolve();
              } else {
                context.commit(MutationTypes.SetCartId, null);
                resolve();
              }
            })
            .catch((error) => {
              debugger
              console.log(error)
              const errors = error.response.data.errors;
              errors.forEach((error: any) => {
                notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
              reject(error);
            });
        } else if(cartId) {
          fetchCart(context, cartId, accessKey)
        }
        //debugger
        
        resolve();
      });
    },
    addCartItem: (context, params) => {
      const cartId = getters.cartId(state);
      const accessKey = params?.accessKey || undefined;
      let cartPost = {
        customizedProductId: params.customizedProductId,
        orderId: cartId ?? undefined,
        quantities: params.quantities || undefined,
        totalQuantity: params.totalQuantity || undefined
      } as OrderCartItemCreateModel;
      return new Promise<void>((resolve, reject) => {
        if (cartId) {
          CartItems.cartItemsCreateCartItemPost(accessKey, cartPost)
            .then(async (res: any) => {
              if (res.data.succeeded) {
                // console.log("cart item created", res);
                window.dataLayer?.push({'event': 'add_to_cart'}) // GTM Event
                facebookAddToCartEvent();
                resolve(res);
              } else {
                reject(res);
              }
            })
            .catch((error) => {
              console.log(error)
              const errors = error.response.data.errors;
              errors.forEach((error: any) => {
                notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
              reject(error);
            });
        } else {
          createCart(context, params).then(async (id: any) => {
            if (id) {
              cartPost = {
                customizedProductId: params.customizedProductId,
                orderId: id,
                quantities: params.quantities || undefined,
                totalQuantity: params.totalQuantity || undefined
              } as OrderCartItemCreateModel;

              CartItems.cartItemsCreateCartItemPost(accessKey, cartPost)
                .then((res: any) => {
                  if (res.data.succeeded) {
                    // console.log("cart item created", res);
                    window.dataLayer?.push({'event': 'add_to_cart'}) // GTM Event
                    facebookAddToCartEvent();
                    resolve(res);
                  } else {
                    reject(res);
                  }
                })
                .catch((error) => {
                  console.log(error)
                  const errors = error.response.data.errors;
                  errors.forEach((error: any) => {
                    notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
                  });
                  reject(error);
                });
            }
          });
        }
      });
    },
    updateCartItem: (context, params) => {
      const cartId = getters.cartId(state);
      const accessKey = params?.accessKey || undefined;
      return new Promise<void>((resolve, reject) => {
        CartItems.cartItemsUpdateCartItemIdPut(params.id, accessKey, params.quantities)
          .then((res: any) => {
            if (res.data.succeeded) {
              // console.log("cart item updated", res);
              store.dispatch("checkout/refreshCart", {
                id: cartId,
                accessKey: accessKey
              });
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => {
            console.log(error)
            if(['Authorization', 'Validation'].includes(error.response.data.resultFailureType) && (router.currentRoute.value.name !== 'Login')) {
              const path = window.location.pathname;
              router.push({name: 'Login', query: { returnUrl: path, clear: 'true' }})
            } else {
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            })
            }
            reject(error);
          });
      });
    },
    removeCartItem: (context, params) => {
      const cartId = getters.cartId(state);
      const accessKey = params?.accessKey || undefined;
      return new Promise<void>((resolve, reject) => {
        CartItems.cartItemsIdDelete(params.id, accessKey)
          .then((res: any) => {
            if (res.data.succeeded) {
              // console.log("cart item removed", res);
              window.dataLayer?.push({'event': 'remove_from_cart'}) // GTM Event
              store.dispatch("checkout/refreshCart", {
                id: cartId,
                accessKey: accessKey
              });
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => {
            console.log(error)
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
            reject(error);
          });
      });
    },
    refreshCart: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        const accessKey = params?.accessKey || undefined;
        const setStore = params.setStore === false ? false : true;
        const cartId = getters.cartId(state);

        if(params.id) {
          Carts.cartsGetCartGet(params.id, accessKey)
          .then((res: any) => {
            if (res.data.succeeded) {
              const cartData = res.data.resultData;
              if((cartId && (cartId === params.id)) || (setStore && ['Cart', 'CartValidated', 'PreparingQuote', 'AmendQuote'].includes(cartData.orderStatusId))) {
                context.commit(MutationTypes.SetCartId, params.id);
                context.commit(MutationTypes.SetCart, cartData);
              } else {
                if (cartId && (cartId !== params.id)) {
                  store.dispatch("checkout/refreshCart", {
                    id: cartId
                  });
                }
              }
              // console.log("cart refresh", res);
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => {
            console.log(error)
            if(['Authorization', 'Validation'].includes(error.response.data.resultFailureType) && (router.currentRoute.value.name !== 'Login')) {
              const path = window.location.pathname;
              router.push({name: 'Login', query: { returnUrl: path, clear: 'true' }})
            } else {
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            })
          }

            // const errors = error.response.data.errors;
            // errors.forEach((error: any) => {
            //   notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            // });
            reject(error);
          });
        } else {
          // console.error("cart set null"); 
          context.commit(MutationTypes.SetCart, null);
          context.commit(MutationTypes.SetCartId, null);
          resolve()
        }     
      });
    },
    clearCartStates: async (context, params) => {    
      context.commit(MutationTypes.SetCartId, null);
      context.commit(MutationTypes.SetCart, null);
    }
  },
  namespaced: true,
};
