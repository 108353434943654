
import { Vue, Options } from "vue-class-component";

@Options({
  components: { },
  props: {
    content: { default: '' },
    dismiss: { default: 'Close'},
    html: { default: '' },
    confirm: { default: 'Yes'},
    title: { default: null },
    showConfirm: { default: true },
    id: { default: 'basciPromptModal' }
  },
  emits: ["dismiss", "confirm"]
})
export default class BasciPromptModal extends Vue {
}
