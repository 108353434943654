import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f29c4f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered modal-dialog-scrollable" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  key: 0,
  class: "modal-header"
}
const _hoisted_5 = {
  class: "modal-title text-uppercase primary-font-bold text-primary",
  id: "basciPromptModalLabel"
}
const _hoisted_6 = { class: "modal-body p-sm-primary" }
const _hoisted_7 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.id,
    tabindex: "-1",
    "aria-labelledby": "basciPromptModalLabel",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.title), 1),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('dismiss'))),
                type: "button",
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.content) + " ", 1),
          _renderSlot(_ctx.$slots, "extraContent", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('dismiss'))),
            type: "button",
            class: "btn-sm-secondary text-uppercase",
            "data-bs-dismiss": "modal"
          }, _toDisplayString(_ctx.dismiss), 1),
          (_ctx.showConfirm)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (this.$emit('confirm'))),
                type: "button",
                class: "btn-sm-secondary text-uppercase",
                "data-bs-dismiss": "modal"
              }, _toDisplayString(_ctx.confirm), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 8, _hoisted_1))
}