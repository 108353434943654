import { OrderHistoryViewModel } from "@/api-client";
import { event, purchase } from "vue-gtag";
import { Vue } from "vue-class-component";

export class TrackPurchase extends Vue {
  trackPurchase(order: OrderHistoryViewModel) {
    if(process.env.VUE_APP_ENABLE_GTM === 'true') {
      window.dataLayer?.push({ event: "purchase" }); // GTM Event

      event("purchase", {
        send_to: "G-ZXSGBJ79FN",
        transaction_id: order.referenceNumber,
        value: order.orderTotal,
        shipping: order.deliveryTotal,
        currency: order.currency.code,
        coupon: order.voucher?.redemptionCode || undefined,
        items: order.orderItems.map((item, index) => {
          return (
            {
              item_id: item.customizedProduct.code,
              item_name: item.customizedProduct.name,
              coupon: order.voucher?.redemptionCode || undefined,
              index: index,
              item_brand: "Clifton Clothing",
              price: item.price,
              quantity: item.quantity
            }
          )
        })
      });

      event("conversion", {
        send_to: "AW-366763012/maB7COz0qtMCEIS48a4B",
        enhanced_conversion_data: {
          email: order.customerContacts?.length ? order.customerContacts[0].email : undefined
        },
        value: order.orderTotal,
        currency: order.currency.code,
        transaction_id: order.referenceNumber,
      });
    }
  }
}
