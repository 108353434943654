
import { Options, Vue } from "vue-class-component";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";

@Options ({
  props: {
    path: { default: '' },
    width: { default: '100%' },
    height: { default: '100%' },
    isFront: false
  },
  emits: ['loaded']
})
export default class PreviewImage extends Vue {
    path = ''
    svgData = ''
    width = ''
    height = ''
    isFront = false

    async created(){
        //debugger
        let data = await globalAxios
            .get(this.path)
            .then((x: any) => x.data)
        data = data.replace('style="max-width: 680px; width: 100%; height: 100%;"', `style="width: ${this.width}; height: ${this.height};"`)
        data = data.replace('style="cursor: grab;"', '')
        data = data.replace('hidden=""', '')
        data = data.replaceAll('id="', `id="${this.$.uid}`)
        data = data.replaceAll('url(#', `url(#${this.$.uid}`)
        
        this.svgData = data;

        if(this.isFront) {
          this.$emit('loaded')
        }
        
        //debugger
    }

}
