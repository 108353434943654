
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    show: Boolean,
  },
  emits: ["close"],
})
export default class BasicVueModal extends Vue {
  show = false;
}
