
import { Options, Vue } from "vue-class-component";

@Options({
  props: { 
    message: { default: '' },
    showToast: { default: '' }
  },
  emits: ['close']
})
export default class Toast extends Vue {
  showToast = false;
  tempToast:any;

  created() {
    clearTimeout(this.tempToast);
    this.$watch('showToast', () => {
      if(this.showToast) {
        this.tempToast = setTimeout(() => {
          this.showToast = false;
          this.$emit('close')
        }, 5000)
      }
    })
  }

  beforeUnmount() {
    clearTimeout(this.tempToast);
  }
}
