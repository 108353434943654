
import { mixins, Options, Vue } from "vue-class-component";
import Toast from "@/components/toast/Toast.vue";
import { FormatDate, UploadPath } from "@/mixins/utilities";
import PreviewImage from "@/components/images/PreviewImage.vue";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { ref } from "@vue/reactivity";

@Options({
  components: { Toast, PreviewImage, Splide, SplideSlide },
  props: {
    product: { default: null },
    linkTo: {default: {name: "ProductSelector"}},
    imageUrl: {default: ''},
    backImageUrl: {default: ''},
    name: {default: 'name'},
    isSVG: {default: false},
    buttonText: {default: 'Edit'},
    showButton: {default: true},
    bg: {default: 'secondary-light'},
    showClose: {default: true},
    border: {default: false},
    extraDetails: {default: false},
    addToStore: {default: false},
    allowShare: {default: true},
    magnify: {default: false}
  },
  emits: ['loaded', 'remove', 'share', 'add', 'showMagnify'],
})
export default class ProfileProductTile extends mixins(UploadPath, FormatDate) {
  locHost = "";
  designLink = "";
  product: any = null;
  showToast = false;
  imageUrl = '';
  isSVG = false;
  splide:any = ref();
  spliderConfig = {
    type: 'slide',
    width: '100%',
    autoHeight: true,
    cover: true,
    arrows: false,
    pagination: false,
    rewind: true,
    classes: {arrow: 'custom-arrow'}
  }


  created() {
    if (this.product?.id && this.isSVG) {
      this.locHost = `${window.location.protocol}//${window.location.host}`;
      this.designLink = `${this.locHost}/designer?saved-design=${this.product.id}`;
    }
  }

  mounted() {
    let allRefs = this.$refs as any;
    this.splide = allRefs.splide;
  }

  get determineImg() {
    if (this.imageUrl) {
      if(this.imageUrl === 'c7a0dd001c17cb361548cac1c2f823e6295a5470/c2b88da6325355782906e141a0f405bf.png') {
        return "/img/no-load.png";
      } else {
        return `${this.uploadPath}/${this.imageUrl}`;
      }  
    } else {
      return "/img/no-load.png";
    }
  }

  flip() {    
    this.splide.go('>');
  }

  copyLink(product: any) {
    debugger
    // Write to the clipboard
    if(this.designLink) {
      let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
      if (!isMobile) {
        this.$emit('share', {id: product.id, type: product.productName, img: product.previewImage});
      }
      else {
        const sharing = async () => {
          if (navigator.share) {
            await navigator.share({
              title: 'CLIFTON | Truly Custom Clothing',
              text: 'View my design created using CLIFTON!',
              url: this.designLink
            })
            .then(() => {
                this.$notify({ type: "success", text: "Design link copied to clipboard." });
              }
            )
            .catch(err => {
                if (err.toString().indexOf("cancel")) {
                  this.$notify({ type: "warning", text: "Copy of share link cancelled." });
                }
                else {
                  this.$notify({ type: "error", text: `Error: ${err}` });
                }
              }
            );
          }
          else {
            navigator.clipboard.writeText(this.designLink);
            this.$notify({ type: "success", text: "The current link has been copied to your clipboard." });
          }
        }

        sharing();
      }
    }
  }

  removeProduct(id: string) {
    this.$emit('remove', id);
  }

  addProductToStore(product:any) {
    this.$emit('add', product);
  }
}
