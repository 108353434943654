// export function joinSlug(slug: Array<string>): string {
//   if (slug.length > 1) {
//     return slug.join("/");
//   }
//   return slug[0];
// }

const utilities = {
  joinSlug(slug: Array<string> | string): string {
    if(typeof slug == 'string') {
      return slug
    }

    if (slug.length > 1) {
      return slug.join("/");
    }
    return slug[0];
  },
  splitSlug(slug: string) {
    if(slug) {
      return slug.split("/");
    }
    return ''
  },
};

export default utilities;
