import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-mask basic-modal"
}
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container d-flex flex-column p-2" }
const _hoisted_4 = { class: "modal-header m-2 p-0 position-relative" }
const _hoisted_5 = { class: "h-sm-secondary" }
const _hoisted_6 = { class: "d-flex col-12 justify-content-end position-absolute close-button-container" }
const _hoisted_7 = { class: "modal-body m-2 py-3 p-0 h-100" }
const _hoisted_8 = { class: "modal-footer m-2 ps-1 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "basic-modal" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "header")
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", {
                      class: "p-1 close-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                    }, [
                      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-xmark" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _renderSlot(_ctx.$slots, "body", {
                    class: _normalizeClass(["h-100", {'invisible': !_ctx.show}])
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _renderSlot(_ctx.$slots, "footer")
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}