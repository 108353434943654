import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d1fdbd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "client-banner-container w-100 d-flex bg-primary-light py-4"
}
const _hoisted_2 = { class: "col-12 d-flex justify-content-center justify-content-md-end client-logos-container" }
const _hoisted_3 = { class: "col-12 d-flex justify-content-end flex-wrap" }
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_ctx.selectedClientLogos.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Splide, {
              class: "w-100",
              options: _ctx.options,
              extensions: _ctx.extensions,
              ref: "logoSplide"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedClientLogos, (logo) => {
                  return (_openBlock(), _createBlock(_component_SplideSlide, {
                    class: "logos",
                    key: logo.id
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "img-fluid px-2",
                        src: logo.imageUrl ? `${_ctx.uploadPath}/${logo.imageUrl}` : '',
                        alt: `${logo.name} logo`
                      }, null, 8, _hoisted_4)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["options", "extensions"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}