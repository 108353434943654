import { Vue } from "vue-class-component";
import { store } from "@/store";
import { CountryViewModel, DeliveryType, OrderStatus } from "@/api-client";
import { GeneralSettings } from "@/network/api";
import { useMeta } from "vue-meta";

function bankersRoundingFunc(num: number, decimalPlaces: number) {
  const d = decimalPlaces || 0;
  const m = Math.pow(10, d);
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n),
    f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);

  return d ? (r / m).toFixed(2) : r.toFixed(2);
}

export class ScrollTop extends Vue {
  scrollTop(toScrollSelector: string, scrollIfAbove = true) {
    const destination = document.querySelector(toScrollSelector) as HTMLElement;
    const navBar = document.querySelector("#navbar") as HTMLElement;
    const scrollOffset = destination?.offsetTop - (navBar ? navBar?.offsetHeight : 0);

    if (scrollOffset > window.scrollY && !scrollIfAbove) {
      return;
    }

    window.scrollTo(0, scrollOffset);
    return;
  }
}

export class UploadPath extends Vue {
  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }
}

export class IsIOS extends Vue {
  get isIOS() {
    return navigator.userAgent.includes("Mac");
  }
}

export class IsLoggedIn extends Vue {
  get isLoggedIn() {
    return store.getters["auth/isLoggedIn"];
  }
}

export class UserRole extends Vue {
  get userRole() {
    return store.getters["user/role"];
  }
}

export class LoadImage extends Vue {
  loadImage = (url: any) =>
    new Promise((resolve, reject) => {
      const img = new Image() as any;
      img.addEventListener("load", () => resolve(img));
      img.addEventListener("error", (err: Error) => reject(err));
      img.src = url;
    });
}

export class BankersRounding extends Vue {
  bankersRounding(num: number, decimalPlaces: number) {
    return bankersRoundingFunc(num, decimalPlaces);
  }
}

export class ThousandSeparator extends Vue {
  thousandSeparator(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export class CalculatePrice extends Vue {
  calculatePrice = (price: number, multiplier: number, decimalPlaces = 2) => {
    if (price && price == 0) {
      return (0).toFixed(decimalPlaces);
    } else {
      if (price && multiplier) {
        return bankersRoundingFunc(price * multiplier, decimalPlaces);
      } else {
        return (0).toFixed(2);
      }
    }
  };
}

export class QuantityPerItem extends Vue {
  quantityPerItem(item: any) {
    const totalQty = 0;
    const sum = item.quantities.reduce((acc: any, curr: any) => {
      return acc + curr.quantity;
    }, totalQty);

    return sum;
  }
}

export class Currency extends Vue {
  currency() {
    const country = store.getters["location/country"];
    return (
      country?.currency ?? {
        id: "",
        name: "",
        code: "USD",
        symbol: "$",
        pricingMultiplier: 1,
      }
    );
  }
}

export class Country extends Vue {
  country() {
    const country = store.getters["location/country"] as CountryViewModel;
    if (country) {
      return country;
    } else {
      return {
        id: "",
        name: "",
        code: "",
        isDefault: false,
        deliveryTypes: [],
        currency: {
          id: "",
          name: "",
          code: "USD",
          symbol: "",
          pricingMultiplier: 0,
          decimalPlaces: 2,
        },
        allowBankTransfers: false,
        bankTransferDetails: "",
      };
    }
  }
}

export class Cart extends Vue {
  cart() {
    const cart = store.getters["checkout/cart"];
    console.log("cart x", cart);
    if (cart && cart.id !== "") {
      return cart;
    } else {
      return {
        id: "",
        items: [],
        countryId: "",
        isGift: false,
        deliveryTypeId: DeliveryType.Standard,
        orderStatusId: OrderStatus.Cart,
        currencyId: "",
        requiresCustomerApproval: true,
        deliveryAddress: {
          addressLine1: "",
          area: "",
          country: "",
          postalCode: "",
        },
        billingAddress: {
          addressLine1: "",
          area: "",
          country: "",
          postalCode: "",
        },
        customerContact: {
          name: "",
          email: "",
          phoneNumber: "",
        },
      };
    }
  }
}

export class GetPriceByQuantity extends Vue {
  getPriceByQuantity(prices: any, quantity: number) {
    if (quantity && prices.length) {
      let price = 0;
      const range = prices.filter((x: any) => (x.minimumQuantity ?? Number.MIN_SAFE_INTEGER) <= quantity! && (x.maximumQuantity ?? Number.MAX_SAFE_INTEGER) >= quantity!)[0];
      if (range != null) {
        price += range.price;
      }
      return price;
    }
  }
}

export class FormatDate extends Vue {
  formatDate(date: any, uppercase = true) {
    function appendLeadingZeroes(n: any) {
      if (n <= 9) {
        return "0" + n;
      }
      return n;
    }
    if (date) {
      if (typeof date === "string") {
        date = new Date(date);
      }
      const dateLocale = date.toLocaleDateString("en-US");

      let months;
      if (uppercase) {
        months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      } else {
        months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      }

      const dateToFormat = new Date(dateLocale.replace(/-/g, "/"));
      const formattedDate = appendLeadingZeroes(dateToFormat.getDate()) + " " + months[dateToFormat.getMonth()] + " " + dateToFormat.getFullYear();
      return formattedDate;
    }
    return "";
  }
}

export class SetMetaData extends Vue {
  hasProp(obj: any = null, prop: string) {
    if (obj && prop) {
      const hasProp = Object.prototype.hasOwnProperty.call(obj, prop);

      if (hasProp) {
        return obj[prop] !== "" ? true : false;
      }
    }
    return false;
  }

  setMetaData(meta: any, obj: any = null, definer = "id", prefix = "") {
    //https://vue-meta.nuxtjs.org/faq/#unique-metadata
    //https://github.com/nuxt/vue-meta/blob/next/examples/vue-router/App.ts
    //https://newspatrak.com/javascript/how-to-use-vue-3-meta-with-vue-js-3/

    if (obj) {
      // if (definer === "script") {
      //   meta.script = obj;
      // } else {
        if (!this.hasProp(obj, definer)) {
          meta.title = prefix ? `${prefix}CLIFTON | Truly Custom Clothing` : "";
          meta.description = "";
          meta.keywords = "";
          meta.og = {
            title: prefix ? `${prefix}CLIFTON | Truly Custom Clothing` : "",
            description: "",
          };
        } else {
          meta.og = {};

          if (this.hasProp(obj, "seoTitle")) {
            meta.title = this.hasProp(obj, "seoTitle") ? prefix + (obj?.seoTitle || "") : "";
            meta.og.title = this.hasProp(obj, "seoTitle") ? prefix + (obj?.seoTitle || "") : prefix ? `${prefix} | CLIFTON | Truly Custom Clothing` : "";
          }

          if (this.hasProp(obj, "seoDescription")) {
            meta.description = this.hasProp(obj, "seoDescription") ? obj?.seoDescription || "" : "";
            meta.og.description = this.hasProp(obj, "seoDescription") ? obj?.seoDescription || "" : "";
          }

          if (this.hasProp(obj, "seoKeywords")) {
            meta.keywords = this.hasProp(obj, "seoKeywords") ? obj?.seoKeywords || "" : "";
          }

          if (this.hasProp(obj, "noIndex") && obj.noIndex) {
            meta.meta = [{ name: "robots", Name: "robots", content: "noindex, follow" }];
          } else if (this.hasProp(obj, "noIndex") && !obj.noIndex) {
            meta.meta = [];
          }

          if (this.hasProp(obj, "canonicalUrl") && obj.canonicalUrl) {
            meta.link = [{ rel: "canonical", href: obj.canonicalUrl }];
          } else if (this.hasProp(obj, "canonicalUrl") && !obj.canonicalUrl) {
            meta.link = [];
          }

          if(this.hasProp(obj, "script")) {
            meta.script = obj.script;
          }
        // }

        meta.og = {
          ...meta.og,
          url: `https://www.cliftonclothing.com${window.location.pathname}`,
          type: "",
        };
      }
    }
  }
}

export class CompareObjects extends Vue {
  compareObjects(obj1: any, obj2: any): boolean {
    const obj1Keys = Object.keys(obj1).sort();
    const obj2Keys = Object.keys(obj2).sort();
    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    } else {
      const areEqual = obj1Keys.every((key, index) => {
        const objValue1 = obj1[key];
        const objValue2 = obj2[obj2Keys[index]];
        return objValue1 === objValue2;
      });
      if (areEqual) {
        return true;
      } else {
        return false;
      }
    }
  }
}

export class HandleSlug extends Vue {
  joinSlug(slug: Array<string> | string): string {
    if (typeof slug == "string") {
      return slug;
    }

    if (slug.length > 1) {
      return slug.join("/");
    }
    return slug[0];
  }

  splitSlug(slug: string) {
    if (slug) {
      return slug.split("/");
    }
    return "";
  }
}

export class FormatEnum extends Vue {
  formatEnum(str: string): string {
    const res = str.replace(/([A-Z])/g, " $&");
    return res;
  }
}

export class ZohoChat extends Vue {
  get zohoChatActive() {
    return store.getters["user/zohoActive"];
  }

  startZohoChat() {
    if((window as any)?.$zoho) {
        // (window as any)?.$zoho.salesiq.chat.start();
      
      // not a great solution but at the time of implementing this was the next best thing as zoho api was unreliable
      const openButton = document.getElementById('zsiq_float');
      if(openButton) {
        openButton.click()
      } 
    }
  }
}

export class HandleError extends Vue {
  handleError(error: any, forceDisplayError = false, customMessage: string | null = null) {
    const hasProp = error ? Object.prototype.hasOwnProperty.call(error, "response") : null;
    debugger
    if (!hasProp && !error && forceDisplayError) {
      this.$notify({ type: "error", text: customMessage || "An unexpected error occurred.", ignoreDuplicates: true, duration: -1 });
    } else if(error && hasProp) {
      const errors = error.response.data.errors;
      errors.forEach((error: any) => {
        this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
      });
    }
  }
}

export class Newsletter extends Vue {
  getMailingList () {
    return new Promise((resolve, reject) => {
      GeneralSettings.generalSettingsKeyGet("newsletterMailingId")
      .then((res) => {
        if (res) {
          resolve(res.data.resultData as string);
        }
      })
      .catch(() => resolve("RAiksg"))
    })
  }

  getNewsletterCopy () {
    return new Promise((resolve, reject) => {
      GeneralSettings.generalSettingsGetGeneralSettingsGet(["newsletterTitle", "newsletterSubtitle", "newsletterTnCCopy"])
      .then((res) => {
        if (res) {
          resolve(res.data.resultData);
        }
      })
      .catch(() => null)
    })
  }

  async newsletterSubscribe(email: string, source: string) {
    if (!email) {
      console.error('📬 Stopped the newsletter opt-in because email appears to be missing');
      return false;
    }
    let mailList: string | null = null;
    await this.getMailingList()
      .then((res: any) => {
        if(res) {
          mailList = res
        }
      })
      .catch(() => mailList = "RAiksg")
    
    return new Promise((resolve, reject) => {
      const PUBLIC_API_KEY = "TX92nv";
      const LIST_ID = mailList;
      const options = {
        method: 'POST',
        headers: {revision: '2023-10-15', 'content-type': 'application/json'},
        body: JSON.stringify({
          data: {
            type: 'subscription',
            attributes: {
              custom_source: source,
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    email: email
                  }
                }
              }
            },
            relationships: {list: {data: {type: 'list', id: LIST_ID}}}
          }
        })
      };

      fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=${PUBLIC_API_KEY}`, options)
        .then(response => {
          if(response.status < 300) {//202 is a success -> https://developers.klaviyo.com/en/reference/create_client_subscription
            this.$notify({ type: "success", text: "Thank you for subscribing to our newsletter!" });
          }
          resolve(response)
        })
        .catch(err =>
          reject(err)
        );
    });
  }
}

export default class Default extends Vue {
  test = "test";
}

export class HasAdminRoles extends Vue {
  hasAdminRoles(roles: Array<string> | null): boolean {
    if (roles) {
      return roles.includes("Admin") || roles.includes("Operations") || roles.includes("Sales") || roles.includes("Sales Admin");
    }
    return false;
  }
}
