
import { Options, Vue } from "vue-class-component"
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
// import loaderJson2 from "@/assets/loader/loader2.json";
import loaderJson3 from "@/assets/loader/loader2-new-colours.json";


@Options({
  props: { visible: Boolean, isAbsolute: Boolean },
  components: { Vue3Lottie }
})
export default class CustomSpinner extends Vue {
  visible = false;
  loader = loaderJson3;
  tipTimer: any;

  tipArray = [
    'As you customize your order, your quote updates in real-time to see the cost.',
    'Choose from our curated selection of patterns, colors, artwork, or add your custom colors, logo, or text.',
    'If you have a big group order, creating a free online store to collect orders is a lifesaver.',
    'All fabrics, inks and dyes are tested to be AZO free, eco-friendly & free of hazardous substances.',
    'Artwork can easily be added to your design using our customization tool.',
    'If you need a style, color or design not shown online - just ask as we can absolutely do it!'
  ]

  arrLength = this.tipArray.length;
  currentTipIndex = Math.floor(Math.random() * this.arrLength);
  usedTips = [this.currentTipIndex];
  displayTip = this.tipArray[this.currentTipIndex]

  created() {   
    clearInterval(this.tipTimer)
    this.tipTimer = setInterval(() => {
      this.randomiseIndex();
    }, 6000)
  }

  randomiseIndex() {
    let randomIndex = Math.floor(Math.random() * (this.arrLength));
    if(this.usedTips.length === this.arrLength) {
      this.usedTips = [];
    }

    if(!this.usedTips.includes(randomIndex)) {
      this.usedTips.push(randomIndex);
      this.displayTip = this.tipArray[randomIndex];  
    } else {
      this.randomiseIndex();
    }
  }

  beforeUnmount() {
    clearInterval(this.tipTimer)
  }
}
