
import { Options, Vue } from "vue-class-component";
// import loaderJson2 from "@/assets/loader/loader2.json";
import loaderJson3 from "@/assets/loader/loader2-new-colours.json";
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

@Options({
  components: { Vue3Lottie },
  props: { 
    visible: {type: Boolean, default: false},
    isAbsolute: {type: Boolean, default: false},
    showMessage: {type: Boolean, default: false}
  },
})
export default class Spinner extends Vue {
  visible = false;
  isAbsolute = false
  loader = loaderJson3;
}
