
import { mixins, Options } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";
import PreviewImage from "@/components/images/PreviewImage.vue";

@Options({
  components: { PreviewImage },
  props: {
    product: { default: {} },
  },
  emits: ['remove'],
})
export default class EnquiryProductTile extends mixins(UploadPath) {
}
