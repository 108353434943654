
import { Vue, Options } from "vue-class-component";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";

@Options({
  components: { HeadingBanner },
})
export default class PageNotFoundBanner extends Vue {
  created() {
    
  }
}
