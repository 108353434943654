import { store } from "@/store";
import axios, { AxiosStatic } from "axios";

function isValid(date:any) {
  return date && new Date().getTime() < new Date(date).getTime();
}

export function ConfigureAxios() {
  axios.interceptors.request.use(
    (req) => {
      const url = new URL(req.url!);

      if (url.pathname.includes("/Identity/Authentication")) return req;

      let tkn = store.getters["auth/token"];
      if(tkn !== null){
        if(!isValid(tkn.refreshTokenExpiration)) {
          store.dispatch("auth/logout") //clear login data (token, profile etc) without actually logging out
          return req;
        }
        if(!isValid(tkn.expiration)) {
          return store.dispatch("auth/refresh").then((res) => {
            tkn = store.getters["auth/token"];
            req.headers!.Authorization = `Bearer ${tkn.encodedToken}`;
            return req;
          });
        }
        if(isValid(tkn.expiration) && tkn.encodedToken){
          req.headers!.Authorization = `Bearer ${tkn.encodedToken}`;
        }
      }
      return req;
    },
    (error) => {
      console.log("ded");
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if(error) {
        const status = error?.response ? error?.response?.status : null;
        if (status === 401) {
          return store.dispatch("auth/refresh").then((tkn) => {
            return axios.request(error.config);
          });
        }
      }
      
      return Promise.reject(error);
    }
  );
}