import { CountryViewModel } from "@/api-client";
import { Countries } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import { RootState } from "..";
import { store } from "@/store";
import { notify } from "@kyvg/vue3-notification";


export type LocationState = {
  country: CountryViewModel;
};

export enum MutationTypes {
  SetCountry = "SET_Country",
}

export const state: LocationState = {
  country: {
    id: "",
    name: "",
    code: "",
    isDefault: false,
    deliveryTypes: [],
    currency: {
      id: '',
      name: '',
      code: 'USD',
      symbol: '',
      pricingMultiplier: 0,
      decimalPlaces: 2
    },
    allowBankTransfers: false,
    bankTransferDetails: ''
  }
};

type Getters = {
  country(state: LocationState): CountryViewModel;
};

export const getters: GetterTree<LocationState, RootState> & Getters = {
  country(state) {
    if (state.country != null && state.country.id) {
      return state.country;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["location"] == null) {
      return null;
    }
    const country = store["location"]["country"];
    if (country != null) {
      state.country = country;
      return country;
    }
    return null;
  },
};

type Mutations = {
  [MutationTypes.SetCountry](state: LocationState, country: any): void;
};

export const mutations: MutationTree<LocationState> & Mutations = {
  [MutationTypes.SetCountry](state, country) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["location"]) {
      store["location"] = {};
    }
    store["location"]["country"] = country;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.country = country;
  },
};

export const LocationModule: Module<LocationState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    ensureLocationSet: async (context, params) => {
      const country = getters.country(state);
      if(!country || (country && !country.id)) {
        return new Promise<void>((resolve, reject) => {
          Countries.countriesGetCountryByIpGeolocationGet()
          .then(async (res) => {  
            if(res.data.succeeded) {
              context.commit(MutationTypes.SetCountry, res.data.resultData);
              await store.dispatch("location/setDefaultSelection", {
                countryId: res.data.resultData
              })
              
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            if(error) {
              console.log(error)
              const errors = error.response.data.errors;
              errors.forEach((error: any) => {
                notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
              reject(error)
            }
          })
          });
      } else {
        let countries = []
        let selectedCountry = null as CountryViewModel | null
        
        await Countries.countriesGet().then((res) => {
          if (res.data.succeeded) {
            countries = res.data.resultData as  Array<CountryViewModel>;
            selectedCountry = countries.find((country: CountryViewModel) => country.id === state.country.id) as CountryViewModel;
            if(selectedCountry) {
              store.dispatch("location/changeLocation", {
                country: selectedCountry
              })
            } else {
              store.dispatch("location/changeLocation", {
                country: state.country
              })
            }
          }
        })
        .catch((error) => {
          if(error) {
            console.log(error)
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
          }
        });
      }
    },
    changeLocation: (context, params) => {
      if(params.country !== null) {
        context.commit(MutationTypes.SetCountry, params.country);
        store.dispatch("location/setDefaultSelection", {
          countryId: params.country.id
        })
      }
    },
    getDefaultSelection: (context, params) => {
      const isLoggedIn = store.getters["auth/isLoggedIn"];
      if(isLoggedIn) {
      return new Promise<void>((resolve, reject) => {      
          Countries.countriesGetLastSelectedCountryGet()
          .then(async (res) => {  
            if(res.data.succeeded) {
              if(res.data.resultData) {
                context.commit(MutationTypes.SetCountry, res.data.resultData);
              } else {
                await store.dispatch("location/ensureLocationSet")
              }
              resolve();
            } else {
              reject(res.data);
            }
          })
          .catch((error) => {
            if(error) {
              console.log(error)
              const errors = error.response.data.errors;
              errors.forEach((error: any) => {
                notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
              reject(error)
            }
          });
      })
      } else {
        store.dispatch("location/ensureLocationSet")
      }
    },
    setDefaultSelection: (context, params) => {
      const isLoggedIn = store.getters["auth/isLoggedIn"];
      if(isLoggedIn) {
      return new Promise<void>((resolve, reject) => {
        Countries.countriesSetLastSelectedCountryPost(params.countryId)
        .then(async (res) => {  
          if(res.data.succeeded) {           
            resolve();
          } else {
            reject(res.data);
          }
        })
        .catch((error) => {
          if(error) {
            console.log(error)
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
            reject(error)
          }
        });
      })
    }
    }
  },
  namespaced: true,
};
