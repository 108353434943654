import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "custom-spinner-bg" }
const _hoisted_2 = { class: "tip-container" }
const _hoisted_3 = { class: "p-0 m-0 text-center p-xs-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["custom-spinner-container", {'absolute': _ctx.isAbsolute, 'flex-centered py-5': !_ctx.isAbsolute}])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Vue3Lottie, {
            class: "custom-spinner",
            animationData: _ctx.loader
          }, null, 8, ["animationData"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.displayTip), 1)
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}