
import { Options, Vue } from "vue-class-component";
import ProfileProductTile from "@/components/profile/ProfileProductTile.vue";
import { store } from "@/store";

@Options({
  components: { ProfileProductTile },
  props: { 
    user: { default: null }
  },
})
export default class RecentlyViewedProducts extends Vue {
  refreshKey = 0

  recentlyViewedProducts() {
    const products = store.getters["user/recentlyViewedProducts"];
    if(products) {
      return products;
    }
    return []
  }

  async removeProduct(id:string) {
    await store.dispatch("user/removeRecentlyViewedProduct", id);
    this.refreshKey += 1;
  }
}
