import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "payment-complete-container container-fluid col-12 m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingBanner = _resolveComponent("HeadingBanner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadingBanner, {
      bannerSrc: '/img/enquiries/enquiry-image-thin-s.jpg',
      title: 'Page Not Found'
    }, null, 8, ["bannerSrc"])
  ]))
}