
import { mixins, Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import { Users } from "@/network/api";
import { ScrollTop } from "@/mixins/utilities";

@Options({
  components: { Form, Field, ErrorMessage },
  props: {
    user: { default: null },
  },
})
export default class ResetPassword extends mixins(ScrollTop) {
  currentPassword = "";
  newPassword = "";
  confirmPassword = "";
  showPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;

  topAnchor() {
    this.scrollTop(".profile-content-container", false);
  }

  togglePassword(fieldName: string) {
    let field;
    if (fieldName === "current-password") {
      field = document.getElementById("current-password") as HTMLInputElement;
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        field.type = "text";
      } else {
        field.type = "password";
      }
    } else if (fieldName === "new-password") {
      field = document.getElementById("new-password") as HTMLInputElement;
      this.showNewPassword = !this.showNewPassword;

      if (this.showNewPassword) {
        field.type = "text";
      } else {
        field.type = "password";
      }
    } else {
      field = document.getElementById("confirm-new-password") as HTMLInputElement;
      this.showConfirmPassword = !this.showConfirmPassword;

      if (this.showConfirmPassword) {
        field.type = "text";
      } else {
        field.type = "password";
      }
    }
  }

  resetPassword(values: any) {
    if (values.CurrentPassword === values.NewPassword && values.CurrentPassword === values.ConfirmationPassword) {
      this.$notify({ type: "error", text: "Please update using a new password." });
    } else {
      Users.usersUpdateUserPasswordPut({
        oldPassword: values.CurrentPassword,
        newPassword: values.NewPassword,
        newPasswordConfirmation: values.ConfirmationPassword,
      })
        .then((res) => {
          if (res.data.succeeded) {
            this.$notify({ type: "success", text: "Password updated." });
          }
        })
        .catch((error) => {
console.log(error)
          let errors = error.response.data.errors;

          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    }
  }
}
