
import { Options, Vue } from "vue-class-component";
import ProfileProductTile from "@/components/profile/ProfileProductTile.vue";
import ShareModal from "@/components/share/ShareModal.vue";
import { CustomizedProductListViewModel, CustomizedProductListViewModelPaginatedList } from "@/api-client";
import { CustomizedProducts } from "@/network/api";
import Spinner from "@/components/spinner/Spinner.vue";

@Options({
  components: { ProfileProductTile, ShareModal, Spinner },
  props: { 
    user: { default: null }
  },
})
export default class SavedDesigns extends Vue {
  savedDesignPages: CustomizedProductListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 12,
  };
  savedDesigns: Array<CustomizedProductListViewModel> = []
  shareId = "";
  pageIndex = 1;
  loading = false;
  productsLoaded = true;
  loaded = 0;
  showShareModal = false
  productTitle = "";
  previewPath = "";

  created() {
    this.savedDesigns = [];
    this.getSavedDesigns();
  }

  mounted() {
    this.scroll();
  }


  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  scroll() {
    window.onscroll = () => {
      let allRefs = this.$refs as any;
      let scrolledTo = allRefs.bottom;

      if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
        this.loadMoreDesigns();
      }
    };
  }

  getSavedDesigns(pageIndex = 1, pageSize = 12) {
    this.loading = true;   
    CustomizedProducts.customizedProductsGetCustomizedProductsGet(pageIndex, pageSize)
    .then((res) => {
      if(res.data.succeeded) {
        this.savedDesignPages = res.data.resultData!;
        this.savedDesignPages.items.forEach((item:any) => {
          //this is for lazy loading
          this.savedDesigns.push(item);
        });

        // console.log(this.savedDesigns);      
      }
        this.loading = false;

    })   
    .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadMoreDesigns() {
    if ((this.loading === false) && (this.savedDesignPages.hasNextPage === true)) {
      this.loading = true;
      this.pageIndex += 1;

      this.getSavedDesigns(this.pageIndex);
    }
  }

  determineLoad() {
      this.loaded += 1;
      if(this.loaded >= (this.savedDesignPages.totalCount - 2)) {
        this.productsLoaded = true;
      }
  }

  removeProduct(id:string) {
    this.loading = true;
    CustomizedProducts.customizedProductsIdDelete(id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$notify({ type: "success", text: "Saved design deleted.", ignoreDuplicates: true });     
        this.savedDesigns = this.savedDesigns.filter((item:any) => {
          return item.id !== id;
        });
      }
      this.loading = false;
    })   
    .catch((error) => {
console.log(error)
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  shareProduct(product: any) {
    this.showShareModal = true;
    this.shareId = product.id;
    this.productTitle = product.type;
    this.previewPath = product.img;
  }

  beforeUnmount() {
    this.savedDesigns = [];
  }
}
