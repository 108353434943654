
import { mixins, Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import AddressTile from "@/components/profile/address-book/AddressTile.vue";
import EditAddress from "@/components/profile/address-book/EditAddress.vue";
import { UserAddressListViewModel, UserViewModel } from "@/api-client";
import { store } from "@/store";
import countries from "@/assets/country-codes/countries.json";
import Spinner from "@/components/spinner/Spinner.vue";
import { ScrollTop } from "@/mixins/utilities";

@Options({
  components: { Form, Field, ErrorMessage, AddressTile, EditAddress, Spinner },
  props: {
    user: {
      default: {
        displayName: "",
        email: "",
        phoneNumber: "",
      },
    },
    addresses: {
      default: [],
    },
    loading: {
      default: false,
    },
  },
  emits: ['loading']
})
export default class ProfileDetails extends mixins(ScrollTop) {
  countryCodes: any = countries;
  selectedDialCode = "";
  phoneNumber = "";
  user: UserViewModel = {
    displayName: "",
    email: "",
    phoneNumber: "",
  };
  clonedUser: UserViewModel = {
    displayName: "",
    email: "",
    phoneNumber: "",
  };
  showAddressFields = false;
  selectedAddress = null;
  addresses: Array<UserAddressListViewModel> = [];

  topAnchor() {
    this.scrollTop(".profile-content-container", false);
  }

  get sortedDialCodes() {
    return this.countryCodes.sort((a: any, b: any) => {
      return a.dialCode - b.dialCode;
    });
  }

  get primaryAddress() {
    if (this.addresses.length > 0) {
      return this.addresses.find((a) => a.isPrimary);
    }
    return null;
  }

  created() {
    this.clonedUser = { ...this.user };
    console.log("cloned user", this.clonedUser);

    if (this.clonedUser.phoneNumber) {
      let array = this.clonedUser.phoneNumber.split("-");
      if (array.length >= 2) {
        this.selectedDialCode = array[0];
        array.shift()
        this.phoneNumber = array.join('-');
      }
    }
    if (this.selectedDialCode == "") {
      let selectedCountry = store.getters["location/country"];

      if (selectedCountry) {
        let matchedCountry = this.countryCodes.find((country: any) => {
          return selectedCountry.code === country.isoCode;
        });

        if (matchedCountry) {
          this.selectedDialCode = matchedCountry.dialCode;
        }
      }

      if (!this.selectedDialCode) {
        this.selectedDialCode = "+1";
      }
    }
  }

  updateDetails() {
    if(this.phoneNumber) {
      let numberWithCode = `${this.selectedDialCode}-${this.phoneNumber}`;
      this.clonedUser.phoneNumber = numberWithCode;
    } else {
      this.clonedUser.phoneNumber = null;
    }
    
    this.$emit('loading', true)
    store
      .dispatch("user/updateProfile", this.clonedUser)
      .then(() => {
        this.$notify({ type: "success", text: "Personal details updated." });
        this.$emit('loading', false)
      })
      .catch((err) => {
        this.$notify({ type: "error", text: "Error updating personal details." });
        this.$emit('loading', false)
        //this.clonedUser = { ...this.user };
      });
    console.log(this.clonedUser);
    this.$emit('loading', false)
  }

  editAddress(address: any) {
    this.selectedAddress = { ...address };
    this.showAddressFields = true;
  }
}
