import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import { defineRule } from 'vee-validate';
import { required, email, min, confirmed, numeric, regex } from '@vee-validate/rules';
import { createMetaManager } from 'vue-meta'

import  "bootstrap";

import { ConfigureAxios } from "@/network/auth-interceptor";
import { UserModule } from "./store/modules/user";
import { AuthModule } from "./store/modules/auth";
import { LocationModule } from "./store/modules/location";
import dayjs from "dayjs";
import vue3GoogleLogin from 'vue3-google-login'
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Markdown from 'vue3-markdown-it';

import VueAppleLogin from "@/social-auth-providers/apple-signin";
import VueFacebookLogin from "@/social-auth-providers/facebook-signin";

import VueClickAway from "vue3-click-away";
import { CheckoutModule } from "./store/modules/checkout";

import Notifications from '@kyvg/vue3-notification'
import { createGtm } from '@gtm-support/vue-gtm';
import VueGtag from "vue-gtag";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLock, faTruck, faTruckFast, faShareNodes, faCircleXmark, faSquarePlus, faChevronLeft, faCirclePlus, faTrash, faPrint, faXmark, faAngleDown, faAngleRight, faSearch, faAngleLeft, faCameraRotate, faEye, faEyeSlash, faPenToSquare, faCopy, faEnvelope, faPlus, faFont, faMinus, faClone, faAnglesUp, faEllipsisVertical, faCircleInfo, faEyeDropper, faCircleQuestion, faCartShopping, faAngleUp, faCirclePlay, faMagnifyingGlassPlus, faCalculator} from '@fortawesome/free-solid-svg-icons'

import { faCcMastercard, faCcApplePay, faCcAmex, faCcVisa, faFacebook, faInstagram, faXTwitter, faLinkedin, faPinterest, faTiktok } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faLock, faTruck, faTruckFast, faShareNodes, faCircleXmark, faSquarePlus, faCcMastercard, faCcApplePay, faCcAmex, faCcVisa, faChevronLeft, faCirclePlus, faTrash, faPrint, faXmark, faFacebook, faInstagram,  faLinkedin, faPinterest, faAngleDown, faAngleRight, faSearch, faAngleLeft, faCameraRotate, faEye, faEyeSlash, faPenToSquare, faCopy, faEnvelope, faPlus, faFont, faMinus, faClone, faAnglesUp, faEllipsisVertical, faCircleInfo, faEyeDropper, faCircleQuestion, faCartShopping, faAngleUp, faCirclePlay, faMagnifyingGlassPlus, faCalculator, faXTwitter, faTiktok)

ConfigureAxios();

store.registerModule("user", UserModule);
store.registerModule("auth", AuthModule);
store.registerModule("location", LocationModule);
store.registerModule("checkout", CheckoutModule);

// Vee Validate Global Rules
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('confirmed', confirmed);
defineRule('numeric', numeric);
defineRule('regex', regex);

const app = createApp(App);
const metaManager = createMetaManager();

/* add font awesome icon component */
app.component('font-awesome-icon', FontAwesomeIcon)

app.use(Notifications)
app.use(metaManager)
app.use(store, key);
app.use(router);
app.use(VueClickAway)
app.use(Markdown)

app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA,
  useEnterprise: true
})

app.use(VueFacebookLogin, {
  appId: "611313240621932",
  cookie: true,
  status: true,
  xfbml: true,
  version: "v13.0",
});


// Account is on google cloud console under team@nologostudios.com user
app.use(vue3GoogleLogin, {
  clientId: '981126275650-g6h394h0133s63ihirg4p1odb3l22rfn.apps.googleusercontent.com'
})

app.use(VueAppleLogin, {
  clientId: "611313240621932",
  scope: "name email",
  redirectURI: process.env.VUE_APP_ROOT + "/login",
  state: Date().toString(),
  usePopup: true,
});

app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_PLACES_KEY,
    libraries: "places"
  }
})

if(process.env.VUE_APP_ENABLE_GTM === 'true') {
  app.use(VueGtag, {
    enabled: process.env.VUE_APP_ENABLE_GTM === 'true' ? true : false, // not working, so used if statement above instead
    includes: [
      { 
        id: "G-ZXSGBJ79FN"
      }
    ],
    config: { 
      id: "AW-366763012",
      params: {
        allow_enhanced_conversions: true,
      }
    }
  })
}


app.use(
  createGtm({
    id: ["GTM-ND87JJC", "GTM-MRKJ569"], // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: process.env.VUE_APP_ENABLE_GTM === 'true' ? true : false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: process.env.VUE_APP_ENABLE_GTM === 'true' ? true : false, // Whether or not display console logs debugs (optional)
  }),
);


app.config.globalProperties.$filters = {
  date(input: Date, formatter: string) {
    if (formatter) {
      return dayjs(input).format(formatter);
    }
    return dayjs(input).format("DD/MM/YYYY");
  },
  splitSlug(slug: string) {
    if(slug) {
      return slug.split("/");
    }
    return ''
  },
  joinSlug(slug: Array<string> | string): string {
    if(typeof slug == 'string') {
      return slug
    }

    if (slug.length > 1) {
      return slug.join("/");
    }
    return slug[0];
  }
};

app.config.globalProperties.$dayjs = dayjs;

app.mount("#app");