
import { Options, mixins } from "vue-class-component";
import { UploadPath } from "@/mixins/utilities";

@Options({
    props: {
        shareId: {type: String, default: ''},
        productName: {type: String, default: ''},
        previewPath: {type: String, default: ''},
        show: Boolean,
    },
    emits: ["close"],
})
export default class ShareModal extends mixins(UploadPath) {
    show = false;
    designLink = "";
    shareId = "";
    
    getOriginSlug() {
        return `${window.location.protocol}//${window.location.host}`;
    }

    getShareLink(shareId: string) {
        this.designLink = `${this.getOriginSlug()}/designer?saved-design=${shareId}`;
        return this.designLink;
    }

    copyLink() {
      navigator.clipboard.writeText(this.getShareLink(this.shareId));
      this.$notify({ type: "success", text: "The current link has been copied to your clipboard." });
    }

    // async getProductImageURL(shareId: string) {
    //     await CustomizedProducts.customizedProductsIdGet(shareId)
    //     .then((res) => {
    //         let designObj = res.data.resultData!;debugger
    //         return designObj.previewImageUrl;
    //     })
    //     .catch((error) => {
    //         let errors = error.response.data.errors;
    //         errors.forEach((error: any) => {
    //             this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
    //         });
    //     });
    // }
}
