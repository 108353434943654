
import { Vue, Options } from "vue-class-component";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

@Options({
  components: { Splide, SplideSlide }
})
export default class InstagramCarousel extends Vue {
  spliderConfig = {
    type: 'loop',
    autoplay: false,
    pagination: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    arrows: true,
    perMove: 1,
    autoWidth: true,
  }
  instaPosts: any[] = []
  postsToShow = 4;
  errors = false;

  created() {
    this.addInstaScripts();
  }

  mounted() {
    this.initInstaFeed();
  }

  addInstaScripts() {
    const checkInstaScript = document.getElementById("instaScript");
    const checkInstaFeed = document.getElementById("instaFeed");

    if(!checkInstaScript) {
      const instaScript = document.createElement("script");
      instaScript.id = "instaScript";
      instaScript.src = `${process.env.VUE_APP_INSTANT_TOKENS_TOKEN}`;
      instaScript.onerror = (error) => {
        this.errors = true;
      }
      document.body.appendChild(instaScript);
    }

    if(!checkInstaFeed) {
      const instaFeed = document.createElement("script");
      instaFeed.id = "instaFeed";
      instaFeed.src = "https://cdn.jsdelivr.net/gh/stevenschobert/instafeed.js@2.0.0rc1/src/instafeed.min.js";
      document.body.appendChild(instaFeed);
    } 
  }

  initInstaFeed() {
    if((window as any).Instafeed && (window as any).InstagramToken) {
      let feed: any = new (window as any).Instafeed({
        accessToken: (window as any).InstagramToken,
        mock: true,
        success: (items:any) => {
          const postsToDisplay = items.data.filter((item:any) => item?.media_type?.toLowerCase() == 'image').slice(0, this.postsToShow);
          this.instaPosts = postsToDisplay;
        },
        error: () => {
          this.errors = true;
        }
      });
      feed.run();
    } else {
      window.addEventListener("load", () => {
        this.initInstaFeed();
      })
    }
  }
}
