import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-mask-spinner flex-centered" }
const _hoisted_2 = { class: "custom-full-spinner-container" }
const _hoisted_3 = { class: "custom-spinner-bg" }
const _hoisted_4 = { class: "tip-full-container" }
const _hoisted_5 = { class: "p-0 m-0 text-center p-xs-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!

  return (_openBlock(), _createBlock(_Transition, { name: "fullpage-spinner" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Vue3Lottie, {
              class: "custom-full-spinner",
              animationData: _ctx.loader
            }, null, 8, ["animationData"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.displayTip), 1)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}