
import { Newsletter } from "@/mixins/utilities";
import { mixins, Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import Spinner from "@/components/spinner/Spinner.vue";


@Options({
  components: { Form, Field, ErrorMessage, Spinner },
  props: {
    shortForm: { default: false },
    source: { default: "" }
  }
})
export default class RegistrationPage extends mixins(Newsletter) {
  displayCSSClass = "hidden";
  source = "";
  email = "";
  shortForm = false;
  loading = false;
  newsletterCopy = {
    "newsletterTitle": "",
    "newsletterSubtitle": "",
    "newsletterTnCCopy": ""
  };
  subscriptionResult: any = null;

  errors: Array<string> | null = null;

  async submit() {
    this.errors = null;
    this.loading = true;
    const subscribeResult: any = await this.newsletterSubscribe(this.email, this.source);
    if (subscribeResult.status < 300) {//202 is a success -> https://developers.klaviyo.com/en/reference/create_client_subscription
      this.subscriptionResult = subscribeResult;
      if(!this.shortForm) {
        setTimeout(() => {
          this.closeNewsletter();
        }, 3500);
      }
    }
    else {
      this.subscriptionResult = null;
    }
    this.loading = false;
  }

  closeNewsletter() {
    this.displayCSSClass = 'hidden';
  }
  
  async mounted() {
    const isPopupClose = sessionStorage.getItem('NewsletterPopupShown');
    if (isPopupClose?.toString() != 'true') {
      await this.getNewsletterCopy()
        .then((res: any) => {
          this.newsletterCopy = res;
          this.displayCSSClass = "";
          sessionStorage.setItem('NewsletterPopupShown', 'true');
        })
    }
  }
}
