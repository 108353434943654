
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";

type HoverColour = "green" | "blue" | "white";

@Options({
  props: {
    hoverColour: {
      type: String as PropType<HoverColour>,
      default: "blue",
    },
  },
})
export default class DesignNowSvg extends Vue {}
