
import { ClientViewModel } from "@/api-client";
import { UploadPath } from "@/mixins/utilities";
import { mixins, Options, Vue } from "vue-class-component";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

@Options({
  components: {
    Splide,
    SplideSlide
  },
  props: {
    clientLogos: { default: [] }
  }
})
export default class ClientSection extends mixins(UploadPath) {
  clientLogos: Array<ClientViewModel> = []
  selectedClientLogos: Array<any> = [];
  numberOfLogos = 12
  options = {
    type: "loop",
    drag: "free",
    autoWidth: true,
    pagination: false,
    arrows: false,
    gap: 0,
    clones: this.clientLogos.length < 12 ? 12 - this.clientLogos.length : undefined,
    autoScroll: {
      speed: 1,
      autoStart: true,
      pauseOnHover: false,
      pauseOnFocus: false
    },
  }
  extensions = {
    AutoScroll
  }

  mounted() {
    this.selectLogos(this.clientLogos)
    this.$watch("clientLogos", () => {
      if(this.selectedClientLogos.length != this.clientLogos.length) {
        this.selectLogos(this.clientLogos);
      }
    });
  }

  getRandomNumber(max: number) {
    //Get random number within total amout of logos
    return Math.floor(Math.random() * max);
  }

  fillArrayWithMinObjects<T>(inputArray: T[], amount = 12): T[] {
    const minSize = amount;
    const resultArray = [...inputArray]; // Copy the original array

    while (resultArray.length < minSize) {
        const randomIndex = Math.floor(Math.random() * inputArray.length);
        resultArray.push(inputArray[randomIndex]);
    }

    return resultArray;
}

  selectLogos(logos: ClientViewModel[]) {
    // Get {{ numberOfLogos }} random logos to display in client section
    if(logos.length){
      let logosTotal = logos.length;
      let count = 0;
      let usedIndexes = [] as Array<number>;
      let random = null as any;

      while(count < this.numberOfLogos && count < logosTotal) {    
        random = this.getRandomNumber(logosTotal);

        if(!usedIndexes.includes(random)) {
          usedIndexes.push(random);
          this.selectedClientLogos.push(this.clientLogos[random])
          count += 1;
        }
      }

      // this.selectedClientLogos = this.fillArrayWithMinObjects(this.selectedClientLogos, 6)
    }
  }
}
