// import { Profile } from "@/network/api";
import { ProductAdminViewModel } from "@/api-client";
import { CustomizedProducts, Users } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import { RootState, store } from "..";
import { notify } from "@kyvg/vue3-notification";


export type CustomPattern = {
  patternImageUrl:string,
  highQualityPatternImageUrl: string | null
}

export type UserState = {
  profile: string | null;
  role: Array<string> | string | null;
  recentlyViewedProducts: Array<ProductAdminViewModel> | null;
  anonymousSavedDesigns: Array<string> | null;
  customPatterns: CustomPattern[] | null;
  zohoActive: boolean | 'loading';
};

export enum MutationTypes {
  SetUserProfile = "SET_UserProfile",
  SetRole = "SET_Role",
  SetRecentlyViewedProducts = "SET_RecentlyViewedProducts",
  SetAnonymousSavedDesigns = "SET_AnonymousSavedDesigns",
  SetCustomPatterns = "SET_CustomPatterns",
  SetZohoActive = "SET_zohoActive"
}

export const state: UserState = {
  profile: null,
  role: null,
  recentlyViewedProducts: null,
  anonymousSavedDesigns: null,
  customPatterns: null,
  zohoActive: 'loading'
};

type Getters = {
  profile(state: UserState): string;
  recentlyViewedProducts(state: UserState): Array<ProductAdminViewModel>;
  anonymousSavedDesigns(state: UserState): Array<string>;
  customPatterns(state: UserState): CustomPattern[];
  zohoActive(state: UserState):  | 'loading';
};

export const getters: GetterTree<UserState, RootState> & Getters = {
  profile(state) {
    if (state.profile != null) {
      return state.profile;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const profile = store["user"]["profile"];
    if (profile != null) {
      state.profile = profile;
      return profile;
    }

    return null;
  },
  role(state) {
    if (state.role != null) {
      return state.role;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const role = store["user"]["role"];
    if (role != null) {
      state.role = role;
      return role;
    }

    return null;
  },
  recentlyViewedProducts(state) {
    if (state.recentlyViewedProducts != null) {
      return state.recentlyViewedProducts;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const recentlyViewedProducts = store["user"]["recentlyViewedProducts"];
    if (recentlyViewedProducts != null) {
      state.recentlyViewedProducts = recentlyViewedProducts;
      return recentlyViewedProducts;
    }

    return null;
  },
  anonymousSavedDesigns(state) {
    if (state.anonymousSavedDesigns != null) {
      return state.anonymousSavedDesigns;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const anonymousSavedDesigns = store["user"]["anonymousSavedDesigns"];
    if (anonymousSavedDesigns != null) {
      state.anonymousSavedDesigns = anonymousSavedDesigns;
      return anonymousSavedDesigns;
    }

    return null;
  },
  customPatterns(state) {
    if (state.customPatterns != null) {
      return state.customPatterns;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const customPatterns = store["user"]["customPatterns"];
    if (customPatterns != null) {
      state.customPatterns = customPatterns;
      return customPatterns;
    }

    return null;
  },
  zohoActive(state) {
    if (state.zohoActive != null) {
      return state.zohoActive;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const zohoActive = store["user"]["zohoActive"];
    if (zohoActive != null) {
      state.zohoActive = zohoActive;
      return zohoActive;
    }

    return 'loading';
  },
};

type Mutations = {
  [MutationTypes.SetUserProfile](state: UserState, profile: string): void;
  [MutationTypes.SetRole](state: UserState, role: any): void;
  [MutationTypes.SetRecentlyViewedProducts](state: UserState, recentlyViewedProducts: Array<ProductAdminViewModel>): void;
  [MutationTypes.SetAnonymousSavedDesigns](state: UserState, anonymousSavedDesigns: Array<string>): void;
  [MutationTypes.SetCustomPatterns](state: UserState, customPatterns: CustomPattern[]): void;
  [MutationTypes.SetZohoActive](state: UserState, zohoActive: boolean): void;
};

function checkForDuplicates<T>(array: T[], item: T, prop: keyof T) {
  if(array) {
    return array.some(function (el) {
      return el[prop] === item[prop];
    });
  }
  return false
}

export const mutations: MutationTree<UserState> & Mutations = {
  [MutationTypes.SetUserProfile](state, profile) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["profile"] = profile;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.profile = profile;
  },
  [MutationTypes.SetRole](state, role) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["role"] = role;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.role = role;
  },
  [MutationTypes.SetRecentlyViewedProducts](state, recentlyViewedProducts) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["recentlyViewedProducts"] = recentlyViewedProducts;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.recentlyViewedProducts = recentlyViewedProducts;
  },
  [MutationTypes.SetAnonymousSavedDesigns](state, anonymousSavedDesigns) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["anonymousSavedDesigns"] = anonymousSavedDesigns;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.anonymousSavedDesigns = anonymousSavedDesigns;
  },
  [MutationTypes.SetCustomPatterns](state, customPatterns) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["customPatterns"] = customPatterns;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.customPatterns = customPatterns;
  },
  [MutationTypes.SetZohoActive](state, zohoActive) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["zohoActive"] = zohoActive;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.zohoActive = zohoActive;
  },
};

export const getInfoFromToken = (token: any) => {
  return (new Promise<any>((resolve, reject) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const parsedJSON = JSON.parse(jsonPayload)
    resolve(parsedJSON);
  }))
}

export const UserModule: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
      getProfile: (context, params) => {
        return new Promise<void>((resolve, reject) => {
          const token = store.getters["auth/token"];
          if(token) {
            Users.usersGetUserGet()
            .then(async (res) => {
              if(res.data.succeeded) {
                context.commit(MutationTypes.SetUserProfile, res.data.resultData);
                const tokenInfo = await getInfoFromToken(token.encodedToken);
                let role = tokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                
                if(typeof role == 'string') {
                  role = [role]
                }
                // debugger
                context.commit(MutationTypes.SetRole, role);
                resolve()
                }
              reject()
            })
            .catch((error) => {
              console.log(error)
              const errors = error.response.data.errors;
              errors.forEach((error: any) => {
                notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
              reject()
            });
          }  
        })
      },
      updateProfile: (context, params) => {
        return new Promise<void>((resolve, reject) => {
          Users.usersUpdateUserPut(params)
            .then((res:any) => {
              if(res.data.succeeded) {
                context.commit(MutationTypes.SetUserProfile, res.data.resultData);
                resolve(res)
              } else {
                reject(res);  
              }
            })
            .catch((error) => {
              console.log(error)
              const errors = error.response.data.errors;
              errors.forEach((error: any) => {
                notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
              reject(error);    
            });
          })
      },
      updatePassword: (context, params) => {
        Users.usersUpdateUserPasswordPut(params)
          .then((res) => {
            //TODO uncomment this?
            //context.commit(MutationTypes.SetUserProfile, res.data);
          })
          .catch((error) => {
            console.log(error)
            const errors = error.response.data.errors;
            errors.forEach((error: any) => {
              notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
          });
      },
    clearProfile: (context, params) => {
      context.commit(MutationTypes.SetUserProfile, null);
      context.commit(MutationTypes.SetRole, null);
    },

    addRecentlyViewedProduct: (context, params) => {
      const products = getters.recentlyViewedProducts(state) || [];
      const exists = checkForDuplicates(products, params, 'id');
      if(params.id !== '') {
        if(!exists) {
          if(products.length < 20) {
              products.unshift(params);
              context.commit(MutationTypes.SetRecentlyViewedProducts, products);
          } else {
            products.pop()
            products.unshift(params);
            context.commit(MutationTypes.SetRecentlyViewedProducts, products);
          }
        }
      }
    },
    removeRecentlyViewedProduct: (context, params) => {
      const products = getters.recentlyViewedProducts(state);
      products.splice(products.findIndex(x => x.id === params), 1);
      context.commit(MutationTypes.SetRecentlyViewedProducts, products);
    },

    addCustomPattern: (context, params:CustomPattern) => {
      const patterns = getters.customPatterns(state) || [];
      const lowResExists = checkForDuplicates(patterns, params, 'patternImageUrl');
      const highResExists = checkForDuplicates(patterns, params, 'highQualityPatternImageUrl');

      if(!lowResExists && !highResExists) {
        patterns.push(params);
        context.commit(MutationTypes.SetCustomPatterns, patterns);
      } else if(lowResExists && (!highResExists && params.highQualityPatternImageUrl)) {
        const match = patterns.find(item => item.patternImageUrl === params.patternImageUrl);
        if(match) {
          match.highQualityPatternImageUrl = params.highQualityPatternImageUrl
          context.commit(MutationTypes.SetCustomPatterns, patterns);
        }
      }
    },
    addAnonymousSavedDesign: (context, params) => {
      const isLoggedIn = store.getters["auth/isLoggedIn"];

      if(!isLoggedIn) {
        const savedDesigns = getters.anonymousSavedDesigns(state) || [];
        const exists = savedDesigns.includes(params)
        if(params !== '') {
          if(!exists) {
            if(savedDesigns.length < 20) {
                savedDesigns.unshift(params);
                context.commit(MutationTypes.SetAnonymousSavedDesigns, savedDesigns);
            } else {
              savedDesigns.pop()
              savedDesigns.unshift(params);
              context.commit(MutationTypes.SetAnonymousSavedDesigns, savedDesigns);
            }
          }
        }
      }
    },
    allocateAnonymousSavedDesigns: (context, params) => {
      if(params?.length) {
        CustomizedProducts.customizedProductsClaimPut(params)
        .then((res) => {
          context.commit(MutationTypes.SetAnonymousSavedDesigns, []);
        })
        .catch((error) => {
          console.log(error)
          // Choosing not to display errors to not confuse users
          // const errors = error.response.data.errors;
          // errors.forEach((error: any) => {
          //   notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          // });
        });
      }
    },
    updateZohoStatus: (context, params) => {
      context.commit(MutationTypes.SetZohoActive, params)
    }
  },
  namespaced: true,
};
