import { FacebookConversionsApi } from "@/network/api";
import { FacebookConversionsEventTypes } from "@/api-client";
import { mixins, Vue } from "vue-class-component";
import { CalculatePrice, Currency } from "@/mixins/utilities";
import { store } from "@/store";

//https://www.facebook.com/business/help/402791146561655?id=1205376682832142
function getUser() {
    return store.getters["user/profile"];
}

async function PushPixelEvent(eventName: FacebookConversionsEventTypes, consoleString: string, object: any = null) {
    const currency = object != null && object.currency ? object.currency : undefined;
    const value = object != null && object.value ? object.value : undefined;
    const user = getUser();

    let eventID = null;
    const capiResponse = await FacebookConversionsApi.facebookConversionsPostEventPost(eventName, user != null ? user.email : undefined, currency, value)
    if(capiResponse && capiResponse.data.succeeded) {
        eventID = { eventID: capiResponse.data.resultData };
        if(process.env.VUE_APP_ENABLE_GTM === 'true') {
            window.fbq('track', eventName, object, eventID);
        }
        else {
            console.log(`%cPixel Event ${eventName}${consoleString}`, "font-size: 20px", object, `ID: ${JSON.stringify(eventID)}`)
        }
    }
    else {
        console.error(`%cAn error occurred pushing the Pixel Event ${eventName}${consoleString}`, "font-size: 20px")
    }
}

export class FacebookAddPaymentInfoEvent extends Vue {
    //The addition of customer payment information during a checkout process. For example, a person clicks on a button to save their billing information.
    facebookAddPaymentInfoEvent() {
        PushPixelEvent(FacebookConversionsEventTypes.AddPaymentInfo, "💳");
    }
}

export class FacebookAddToCartEvent extends Vue {
    //The addition of an item to a shopping cart or basket. For example, clicking an Add to cart button on a website.
    facebookAddToCartEvent() {
        PushPixelEvent(FacebookConversionsEventTypes.AddToCart, "🛒");
    }
}
export function facebookAddToCartEvent() {
    PushPixelEvent(FacebookConversionsEventTypes.AddToCart, "🛒");
}

export class FacebookLeadEvent extends Vue {
    //A submission of information by a customer with the understanding that they may be contacted at a later date by your business.
    //This will be when someone clicks 'send enquiry'.
    facebookLeadEvent() {
        PushPixelEvent(FacebookConversionsEventTypes.Lead, "💬");
    }
}

export class FacebookCustomizeProductEvent extends Vue {
    //The customisation of products through a configuration tool or other application that your business owns.
    facebookCustomizeProductEvent() {
        PushPixelEvent(FacebookConversionsEventTypes.CustomizeProduct, "🎨");
    }
}

export class FacebookInitiateCheckoutEvent extends mixins(Currency, CalculatePrice) {
    //The start of a checkout process. For example, clicking a Checkout button.
    facebookInitiateCheckoutEvent() {
        PushPixelEvent(FacebookConversionsEventTypes.InitiateCheckout, "🛍️");
    }

    itemCount(cart: any, total: any) {
        // const cartObj: any = {
        //     num_items : this.itemCount(cart.items),
        //     content_ids : this.itemsIds(cart.items),
        //     contents : this.itemsInCart(cart.items),
        //     currency : this.currency().code,
        //     value : this.productSubtotal(total),
        // }
        if(cart) {
            let totalItems = 0;
            cart.forEach((item: any) => {
                item.quantities.forEach((itemQuanities: any) => {
                    totalItems += itemQuanities.quantity;
                })
            });
            return totalItems;
        }
        return 0
    }

    itemsIds(cart: any) {
        const itemIdList: any = [];
        if(cart) {
            cart.forEach((item: any) => {
                itemIdList.push(item.id);
            });
        }
        return itemIdList
    }

    itemsInCart(cart: any) {
        const itemIdList: any = [];
        if(cart) {
            cart.forEach((item: any) => {
                itemIdList.push({
                    id: item.customizedProduct.id,
                    name: item.customizedProduct.name,
                    product_price: item.price,
                    totalQuantity: item.customizedProduct.quantities,
                    quantities: {},
                    orderReferenceNumber: item.customizedProduct.orderReferenceNumber,
                });
                item.quantities.forEach((qty: any) => {
                    itemIdList.quantities.push(qty);
                });
            });
        }
        return itemIdList
    }
    
    productSubtotal(total: any) {
        const subtotal: any = this.calculatePrice(total, this.currency().pricingMultiplier)
        return  subtotal * 1
    }
}

export class FacebookPurchaseEvent extends Vue {
    //The completion of a purchase, usually signified by receiving order or purchase confirmation, or a transaction receipt. For example, landing on a thank you or confirmation page.
    facebookPurchaseEvent(purchaseObj: any) {
        PushPixelEvent(FacebookConversionsEventTypes.Purchase, "💲", purchaseObj);
    }
}

export class FacebookViewContentEvent extends Vue {
    //A visit to a web page that you care about. For example, a product or landing page. View content tells you if someone visits a web page's URL, but not what they do or see on that web page.
    //To fire when a collection or product page is visited
    facebookViewContentEvent() {
        PushPixelEvent(FacebookConversionsEventTypes.ViewContent, "👀");
        
        // details: any, categoryOverride: any = []
        // const categoryObj: any = {
        //     content_name: details.name ? details.name : "All Categories",
        //     content_type: categoryOverride.length > 0 || details.primaryCategory ? 'product' : 'product_group',
        // }
        // if(details.id) {
        //     categoryObj['content_ids'] = [details.id];
        // }
        // if(categoryOverride.length > 0) {
        //     categoryObj['content_category'] = categoryOverride[0];
        // }
        // else if(details.primaryCategory) {
        //     categoryObj['content_category'] = details.primaryCategory.slug; //.name
        // }

        // if (categoryObj['content_name'] == "All Categories" && window.location.href != "http://localhost:8080/all") {
        //     return false;
        // }
    }
}